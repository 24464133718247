import React from 'react';
import { object } from 'prop-types';
import ResourcesTab from '@fiverr-private/resources_tab';
import { Button } from '@fiverr-private/fit';
import Nav from '../../lib/Nav';
import withContext from '../../lib/withContext';
import { BusinessProSolutionsNavigation } from '../../components/BusinessProSolutionsNavigation';
import { GuestBecomeSellerLink } from '../../components/GuestLinks/GuestBecomeSellerLink';
import JoinLink from '../../components/JoinLink';
import Layout from '../../components/Layout';
import LeanLocaleSettingsLink from '../../components/LocaleSettingsLinks/LeanLocaleSettingsLink';
import LoginLink from '../../components/LoginLink';
import { Logo } from '../../components/Logo';
import MainRow from '../../components/MainRow';
import Navicon from '../../components/Navicon';

import '../LoggedOutHomepage/style.scss';
import './style.scss';
import { FiverrGoAnimated } from '../../components/FiverrGoLinks/FiverrGoAnimated';

export const LoggedOutHomepageGerman = ({ rollouts }) => {
    const inLynxesGoHeaderEntryRollout = rollouts?.inLynxesGoHeaderEntryRollout;

    return (
        <Layout className="logged-out-homepage-header">
            <MainRow>
                <Navicon />
                <Logo />
                <Nav alignToRight className="nav-with-space">
                    <Nav.Item className="nav-solution-button" displayFromMD>
                        <BusinessProSolutionsNavigation isTransparent={false} />
                    </Nav.Item>
                    <Nav.Item displayFromMD>
                        <ResourcesTab headerType="logged_out" />
                    </Nav.Item>
                    <Nav.Item displayFromLG>
                        <LeanLocaleSettingsLink />
                    </Nav.Item>
                    <Nav.Item displayFromMD>
                        <GuestBecomeSellerLink />
                    </Nav.Item>
                    {inLynxesGoHeaderEntryRollout && (
                        <Nav.Item displayFromXL>
                            <FiverrGoAnimated />
                        </Nav.Item>
                    )}
                    <Nav.Item displayFromSM>
                        <LoginLink />
                    </Nav.Item>
                    <Nav.Item>
                        {inLynxesGoHeaderEntryRollout ? <JoinLink color={Button.COLORS.BLACK} /> : <JoinLink />}
                    </Nav.Item>
                </Nav>
            </MainRow>
        </Layout>
    );
};

LoggedOutHomepageGerman.propTypes = {
    rollouts: object,
};

/**
 * @param {HeaderProps} props
 * @returns {React.ReactElement} Header
 */
export default withContext(LoggedOutHomepageGerman);
